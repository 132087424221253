var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "serviceSheetForm-container" },
    [
      _c(
        "form-panel",
        {
          ref: "form",
          attrs: { form: _vm.form, queryUrl: _vm.queryUrl },
          on: { update: _vm.update },
        },
        [
          _c(
            "col2-block",
            { attrs: { title: "工单流转" } },
            [
              _c("col2-progress", {
                attrs: { status: _vm.progressList, percent: _vm.progress },
              }),
            ],
            1
          ),
          _c(
            "col2-block",
            { attrs: { title: "工单信息" } },
            [
              _c(
                "el-row",
                [
                  _c("col2-item", {
                    attrs: { label: "工单编号", text: _vm.info.serialNumber },
                  }),
                ],
                1
              ),
              _c("col2-item", {
                attrs: { label: "订购服务", text: _vm.info.categoryName },
              }),
              _c("col2-item", {
                attrs: { label: "用户姓名", text: _vm.info.userName },
              }),
              _c("col2-item", {
                attrs: { label: "工单来源", text: _vm.info.source },
              }),
              _c("col2-item", {
                attrs: { label: "联系电话", text: _vm.info.userMobile },
              }),
              _c("col2-item", {
                attrs: { label: "下单时间", text: _vm.info.createTime },
              }),
              _c("col2-item", {
                attrs: { label: "用户地址", text: _vm.info.taskInfoAddress },
              }),
              _c("col2-item", { attrs: { label: "工单状态" } }, [
                _c("span", { staticStyle: { color: "#a94442" } }, [
                  _vm._v(_vm._s(_vm.orderStatusMap[_vm.info.status])),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "col2-block",
            { attrs: { title: "工单内容" } },
            [
              _c(
                "col2-item",
                { attrs: { span: 24, label: "工单图片" } },
                [_c("v-preview", { attrs: { imgs: _vm.picturesList } })],
                1
              ),
              _vm.info.videos
                ? _c("col2-item", { attrs: { span: 24, label: "工单视频" } }, [
                    _c("video", {
                      attrs: {
                        controls: "",
                        src: _vm.info.videos,
                        width: "300",
                        height: "300",
                      },
                    }),
                  ])
                : _vm._e(),
              _c(
                "col2-item",
                { attrs: { span: 24, label: "工单音频" } },
                _vm._l(_vm.info.audios, function (item, index) {
                  return _c("audio", {
                    key: index,
                    attrs: { controls: "", src: item },
                  })
                }),
                0
              ),
              _c("col2-item", {
                attrs: {
                  span: 24,
                  label: "工单描述",
                  text: _vm.info.description,
                },
              }),
            ],
            1
          ),
          false
            ? _c(
                "col2-block",
                { attrs: { title: "处理情况" } },
                [
                  _c("div"),
                  _vm.info.status == 5
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "处理后图片" } },
                        [
                          _c("v-uploader", {
                            ref: "myUpload",
                            attrs: {
                              action: _vm.uploadUrl,
                              imgUrls: _vm.form.imageUrls,
                              limit: 4,
                            },
                            on: {
                              "update:imgUrls": function ($event) {
                                return _vm.$set(_vm.form, "imageUrls", $event)
                              },
                              "update:img-urls": function ($event) {
                                return _vm.$set(_vm.form, "imageUrls", $event)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.afterHanderPic
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "处理后图片" } },
                        [
                          _c("v-preview", {
                            attrs: { imgs: _vm.afterPicturesList },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.operationShow
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "处理反馈" } },
                        [
                          _c("v-textarea", {
                            attrs: { maxlength: 300 },
                            model: {
                              value: _vm.form.operationDes,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "operationDes", $$v)
                              },
                              expression: "form.operationDes",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.info.orderStatus == 2
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "处理状态" } },
                        [
                          _c("v-select", {
                            attrs: { options: _vm.operaOps },
                            model: {
                              value: _vm.form.operaType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "operaType", $$v)
                              },
                              expression: "form.operaType",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.chargeInfo
            ? _c(
                "col2-block",
                { staticClass: "chargeInfo", attrs: { title: "收费信息" } },
                [
                  !_vm.isEditBill
                    ? _c(
                        "div",
                        [
                          _c(
                            "col2-item",
                            { attrs: { span: 24, label: "收费" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.isFee == 1 && !this.isDelete ? "有" : "无"
                                ) + " "
                              ),
                              _vm.isEdit
                                ? _c(
                                    "v-button",
                                    {
                                      staticStyle: { "margin-left": "50px" },
                                      attrs: { type: "text" },
                                      on: { click: _vm.editBill },
                                    },
                                    [_vm._v("修改账单")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.isFee == 1 && !this.isDelete
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "col2-item",
                                    { attrs: { label: "材料费" } },
                                    [
                                      _vm._v(
                                        "\n          " +
                                          _vm._s(
                                            "￥" +
                                              (_vm.materialCost
                                                ? _vm.materialCost
                                                : "0")
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "col2-item",
                                    { attrs: { label: "人工费" } },
                                    [
                                      _vm._v(
                                        "\n          " +
                                          _vm._s(
                                            "￥" +
                                              (_vm.laborCost
                                                ? _vm.laborCost
                                                : "0")
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "col2-item",
                                    { attrs: { label: "应收金额" } },
                                    [
                                      _vm._v(
                                        "\n          " +
                                          _vm._s(
                                            "￥" +
                                              (_vm.totalMoney
                                                ? _vm.totalMoney
                                                : "0")
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "col2-item",
                                    { attrs: { label: "优惠金额" } },
                                    [
                                      _vm._v(
                                        "\n          " +
                                          _vm._s(
                                            "￥" +
                                              (_vm.discountMoney
                                                ? _vm.discountMoney
                                                : "0")
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "col2-item",
                                    { attrs: { label: "实收金额" } },
                                    [
                                      _vm._v(
                                        "\n          " +
                                          _vm._s(
                                            "￥" +
                                              (_vm.paidMoney
                                                ? _vm.paidMoney
                                                : "0")
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "col2-item",
                                    { attrs: { label: "支付状态" } },
                                    [
                                      _vm._v(
                                        "\n          " +
                                          _vm._s(
                                            _vm.payStatus == 1
                                              ? "已支付"
                                              : "未支付"
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "col2-item",
                                    { attrs: { label: "收费账单" } },
                                    [
                                      _c(
                                        "v-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: _vm.toChargeBillManageForm,
                                          },
                                        },
                                        [_vm._v("点击查看")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "col2-item",
                                    { attrs: { label: "订单编号" } },
                                    [
                                      !_vm.orderSerialNumber
                                        ? _c("span", [_vm._v("无")])
                                        : _vm._e(),
                                      _vm.orderSerialNumber
                                        ? _c(
                                            "v-button",
                                            {
                                              attrs: { type: "text" },
                                              on: {
                                                click: _vm.ChargeOrderMgrForm,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.orderSerialNumber)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isEditBill
                    ? _c(
                        "col2-item",
                        { attrs: { label: "收费情况" } },
                        [
                          _c(
                            "div",
                            { staticClass: "item" },
                            [
                              _c(
                                "span",
                                { staticStyle: { "margin-right": "20px" } },
                                [_vm._v("工单收费")]
                              ),
                              _c(
                                "el-radio-group",
                                {
                                  staticClass: "chargeName_name",
                                  on: { change: _vm.changeTicketChargeRadio },
                                  model: {
                                    value: _vm.ticketChargeRadio,
                                    callback: function ($$v) {
                                      _vm.ticketChargeRadio = $$v
                                    },
                                    expression: "ticketChargeRadio",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: "1" } }, [
                                    _vm._v("有"),
                                  ]),
                                  _c("el-radio", { attrs: { label: "2" } }, [
                                    _vm._v("无"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.ticketChargeRadio == 1
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "item" },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        label: "缴费人",
                                        placeholder: "请选择缴费人",
                                        options: _vm.allOwnerOps,
                                      },
                                      model: {
                                        value: _vm.chargeOwner,
                                        callback: function ($$v) {
                                          _vm.chargeOwner = $$v
                                        },
                                        expression: "chargeOwner",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "item" },
                                  [
                                    _c("v-input", {
                                      attrs: {
                                        label: "材料费",
                                        placeholder: "请输入金额",
                                        maxlength: 7,
                                      },
                                      model: {
                                        value: _vm.materialCost,
                                        callback: function ($$v) {
                                          _vm.materialCost = $$v
                                        },
                                        expression: "materialCost",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                      },
                                      [_vm._v("元")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "item" },
                                  [
                                    _c("v-input", {
                                      attrs: {
                                        label: "人工费",
                                        placeholder: "请输入金额",
                                        maxlength: 7,
                                      },
                                      model: {
                                        value: _vm.laborCost,
                                        callback: function ($$v) {
                                          _vm.laborCost = $$v
                                        },
                                        expression: "laborCost",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                      },
                                      [_vm._v("元")]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _c("span", { staticClass: "remark" }, [
                            _vm._v(
                              "注：管理端只能生成工单缴费账单，需物业上门出示收款二维码，业主扫码进行实际支付。"
                            ),
                          ]),
                          _c("v-button", {
                            staticStyle: { "margin-right": "5px" },
                            attrs: { permission: "orderDeal", text: "确认" },
                            on: { click: _vm.confirmEditTicket },
                          }),
                          _c("v-button", {
                            attrs: { text: "取消" },
                            on: { click: _vm.cancelEditTicket },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.progressActionList.length > 0
            ? _c("col2-block", { attrs: { title: "最新工单进度" } }, [
                _c("div", { staticClass: "newProgress" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.progressActionList[0].actionTime)),
                  ]),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.progressActionList[0].name) +
                        " : " +
                        _vm._s(_vm.progressActionList[0].userName) +
                        " "
                    ),
                  ]),
                  _vm.progressActionList[0].tEvaluationInfoV
                    ? _c("span", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.progressActionList[0].tEvaluationInfoV
                                .description
                            ) +
                            " \n        "
                        ),
                      ])
                    : _c("span", [
                        _vm._v(_vm._s(_vm.progressActionList[0].content)),
                      ]),
                  _c("a", { on: { click: _vm.showProgressDetail } }, [
                    _vm._v(">>点击查看详情"),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.info.mEvaluationVList
            ? _c(
                "col2-block",
                { attrs: { title: "用户评价" } },
                [
                  _vm.info.mEvaluationVList.length == 0
                    ? _c("div", { staticClass: "evaluate-wrapper" }, [
                        _vm._v("用户尚未进行评价"),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.info.mEvaluationVList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "evaluate-wrapper" },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("用户评价#" + _vm._s(index + 1)),
                        ]),
                        _c(
                          "div",
                          { staticClass: "rate-row" },
                          _vm._l(
                            item.evaluationItem,
                            function (subItem, subIndex) {
                              return _c(
                                "div",
                                { key: subIndex, staticClass: "col2" },
                                [
                                  _c("span", { staticClass: "rate-label" }, [
                                    _vm._v(_vm._s(subItem.name)),
                                  ]),
                                  _c("el-rate", {
                                    staticClass: "rate",
                                    attrs: {
                                      value: subItem.value,
                                      disabled: "",
                                      "text-color": "#ff9900",
                                    },
                                  }),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        ),
                        item.description
                          ? _c("div", { staticClass: "eva-cell" }, [
                              _c("span", { staticClass: "label" }, [
                                _vm._v("评价内容:"),
                              ]),
                              _c("span", [_vm._v(_vm._s(item.description))]),
                            ])
                          : _vm._e(),
                        item.evaluationTime
                          ? _c("div", { staticClass: "eva-cell" }, [
                              _c("span", { staticClass: "label" }, [
                                _vm._v("评价时间:"),
                              ]),
                              _c("span", [_vm._v(_vm._s(item.evaluationTime))]),
                            ])
                          : _vm._e(),
                        _c("v-preview", { attrs: { imgs: item.imgs } }),
                      ],
                      1
                    )
                  }),
                  _c("div"),
                ],
                2
              )
            : _vm._e(),
          _vm.isEdit && _vm.info.status !== 6
            ? _c(
                "col2-block",
                { attrs: { title: "工单处理" } },
                [
                  _vm.info.status == 5
                    ? _c(
                        "col2-item",
                        { attrs: { span: 50, label: "图片反馈" } },
                        [
                          _c(
                            "v-uploader",
                            {
                              ref: "myUpload",
                              attrs: {
                                action: _vm.uploadUrl,
                                imgUrls: _vm.imgList,
                                limit: 4,
                              },
                              on: {
                                "update:imgUrls": function ($event) {
                                  _vm.imgList = $event
                                },
                                "update:img-urls": function ($event) {
                                  _vm.imgList = $event
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "tip" }, slot: "tip" },
                                [_vm._v("注：建议宽300px高300px，图片小于5M")]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "col2-item",
                    { attrs: { span: 20, label: "处理反馈" } },
                    [
                      _c("v-textarea", {
                        attrs: { placeholder: _vm.defaultFeedback },
                        model: {
                          value: _vm.form.feedback,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "feedback", $$v)
                          },
                          expression: "form.feedback",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.chargeCondition
                    ? _c("col2-item", { attrs: { label: "收费情况" } }, [
                        _c(
                          "div",
                          { staticClass: "item" },
                          [
                            _c(
                              "span",
                              { staticStyle: { "margin-right": "20px" } },
                              [_vm._v("工单收费")]
                            ),
                            _c(
                              "el-radio-group",
                              {
                                staticClass: "chargeName_name",
                                on: { change: _vm.changeTicketChargeRadio },
                                model: {
                                  value: _vm.ticketChargeRadio,
                                  callback: function ($$v) {
                                    _vm.ticketChargeRadio = $$v
                                  },
                                  expression: "ticketChargeRadio",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: "1" } }, [
                                  _vm._v("有"),
                                ]),
                                _c("el-radio", { attrs: { label: "2" } }, [
                                  _vm._v("无"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.ticketChargeRadio == 1
                          ? _c("div", [
                              _c(
                                "div",
                                { staticClass: "item" },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      label: "缴费人",
                                      placeholder: "请选择缴费人",
                                      options: _vm.allOwnerOps,
                                    },
                                    model: {
                                      value: _vm.chargeOwner,
                                      callback: function ($$v) {
                                        _vm.chargeOwner = $$v
                                      },
                                      expression: "chargeOwner",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "item" },
                                [
                                  _c("v-input", {
                                    attrs: {
                                      label: "材料费",
                                      placeholder: "请输入金额",
                                      maxlength: 7,
                                    },
                                    model: {
                                      value: _vm.materialCost,
                                      callback: function ($$v) {
                                        _vm.materialCost = $$v
                                      },
                                      expression: "materialCost",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-left": "10px" } },
                                    [_vm._v("元")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "item" },
                                [
                                  _c("v-input", {
                                    attrs: {
                                      label: "人工费",
                                      placeholder: "请输入金额",
                                      maxlength: 7,
                                    },
                                    model: {
                                      value: _vm.laborCost,
                                      callback: function ($$v) {
                                        _vm.laborCost = $$v
                                      },
                                      expression: "laborCost",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-left": "10px" } },
                                    [_vm._v("元")]
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _c("span", { staticClass: "remark" }, [
                          _vm._v(
                            "注：管理端只能生成工单缴费账单，需物业上门出示收款二维码，业主扫码进行实际支付。"
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.isEdit
            ? _c(
                "div",
                { staticClass: "footerBtn" },
                [
                  _vm.receiving
                    ? _c("v-button", {
                        attrs: {
                          width: 74,
                          text: "接收",
                          permission: "receiving",
                        },
                        on: { click: _vm.workOrderReceiving },
                      })
                    : _vm._e(),
                  _vm.processing
                    ? _c("v-button", {
                        attrs: {
                          width: 74,
                          text: "开始处理",
                          permission: "orderDeal",
                        },
                        on: { click: _vm.workOrderProcessing },
                      })
                    : _vm._e(),
                  _vm.finish
                    ? _c("v-button", {
                        attrs: {
                          width: 74,
                          text: "完成处理",
                          permission: "orderDeal",
                        },
                        on: { click: _vm.workOrderFinish },
                      })
                    : _vm._e(),
                  _vm.transfer
                    ? _c("v-button", {
                        attrs: {
                          permission: "transfer",
                          width: 74,
                          text: "转派",
                        },
                        on: { click: _vm.toTransfer },
                      })
                    : _vm._e(),
                  _vm.info.status == 6 && !_vm.isRevisit
                    ? _c("v-button", {
                        attrs: {
                          width: 74,
                          text: "回访",
                          permission: "returnVisit",
                        },
                        on: { click: _vm.revisit },
                      })
                    : _vm._e(),
                  _vm.close
                    ? _c("v-button", {
                        attrs: { width: 74, text: "关闭", permission: "close" },
                        on: { click: _vm.closeWorkOrder },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("multi-select", {
        attrs: {
          isShow: _vm.isShow,
          searchUrl: _vm.getCountersignListUrl,
          headers: _vm.countersignTableHeader,
          searchParams: _vm.searchParams,
          responseParams: _vm.responseParams,
          responseKey: _vm.responseKey,
          backFill: _vm.form.countersignTypeList,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.isShow = $event
          },
          "update:is-show": function ($event) {
            _vm.isShow = $event
          },
          "update:backFill": function ($event) {
            return _vm.$set(_vm.form, "countersignTypeList", $event)
          },
          "update:back-fill": function ($event) {
            return _vm.$set(_vm.form, "countersignTypeList", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "标签名称" },
                  model: {
                    value: _vm.searchParams.labelName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "labelName", $$v)
                    },
                    expression: "searchParams.labelName",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "二级分类", options: _vm.secondCategoryOps },
                  model: {
                    value: _vm.searchParams.categoryId,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "categoryId", $$v)
                    },
                    expression: "searchParams.categoryId",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "工单进度",
            visible: _vm.dialogProgress,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogProgress = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-timeline",
                _vm._l(_vm.progressActionList, function (item, index) {
                  return _c(
                    "el-timeline-item",
                    { key: index, staticClass: "pro-group" },
                    [
                      _c("div", { staticClass: "progress" }, [
                        _c("div", { staticClass: "progress-top" }, [
                          _c("span", { staticClass: "left" }, [
                            _vm._v(
                              _vm._s(item.name) + " : " + _vm._s(item.userName)
                            ),
                          ]),
                          _c("span", { staticClass: "right" }, [
                            _vm._v(_vm._s(item.actionTime)),
                          ]),
                        ]),
                        item.tEvaluationInfoV
                          ? _c("div", { staticClass: "progress-remark" }, [
                              _vm._v(_vm._s(item.tEvaluationInfoV.description)),
                            ])
                          : _c("div", { staticClass: "progress-remark" }, [
                              _vm._v(_vm._s(item.content)),
                            ]),
                        item.workOrderStatus == 7 && item.content == ""
                          ? _c("span", { staticClass: "progress-remark" }, [
                              _vm._v("工单已完成"),
                            ])
                          : _vm._e(),
                        item.workOrderStatus == 8 && item.content == ""
                          ? _c("span", { staticClass: "progress-remark" }, [
                              _vm._v("工单已关闭"),
                            ])
                          : _vm._e(),
                        item.tEvaluationInfoV
                          ? _c(
                              "div",
                              { staticClass: "progress-view" },
                              [
                                _vm._l(
                                  JSON.parse(
                                    item.tEvaluationInfoV.evaluationItem
                                  ),
                                  function (star, index) {
                                    return _c(
                                      "div",
                                      { key: index, staticClass: "star" },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(star.name) + "："),
                                        ]),
                                        _c("el-rate", {
                                          attrs: { disabled: "" },
                                          model: {
                                            value: star.value,
                                            callback: function ($$v) {
                                              _vm.$set(star, "value", $$v)
                                            },
                                            expression: "star.value",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                _c("v-preview", {
                                  attrs: {
                                    imgs: item.tEvaluationInfoV.images,
                                    imgStyle: _vm.previewStyle,
                                  },
                                }),
                              ],
                              2
                            )
                          : _c(
                              "div",
                              { staticClass: "progress-view" },
                              [
                                _c("v-preview", {
                                  attrs: {
                                    imgs: item.images,
                                    imgStyle: _vm.previewStyle,
                                  },
                                }),
                              ],
                              1
                            ),
                      ]),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加回访记录",
            visible: _vm.dialogRetuenVisit,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogRetuenVisit = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                { attrs: { "label-width": "120px", "label-suffix": "：" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "方式" } },
                    [
                      _c("checkbox-plus", {
                        ref: "returnType",
                        attrs: { options: _vm.returnType },
                        model: {
                          value: _vm.returnForm.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.returnForm, "type", $$v)
                          },
                          expression: "returnForm.type",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "服务质量" } },
                    [
                      _c("rate", {
                        staticClass: "rates",
                        attrs: { "text-color": "#ff9900" },
                        model: {
                          value: _vm.returnForm.quality,
                          callback: function ($$v) {
                            _vm.$set(_vm.returnForm, "quality", $$v)
                          },
                          expression: "returnForm.quality",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "服务速度" } },
                    [
                      _c("rate", {
                        staticClass: "rates",
                        attrs: { "text-color": "#ff9900" },
                        model: {
                          value: _vm.returnForm.speed,
                          callback: function ($$v) {
                            _vm.$set(_vm.returnForm, "speed", $$v)
                          },
                          expression: "returnForm.speed",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "0" } },
                    [
                      _c("v-textarea", {
                        attrs: {
                          placeholder: "请填写回访结果",
                          maxlength: 100,
                        },
                        model: {
                          value: _vm.returnForm.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.returnForm, "content", $$v)
                          },
                          expression: "returnForm.content",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("v-button", {
                attrs: { text: "提 交" },
                on: { click: _vm.submitReturnVisit },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: "物流信息",
            width: "400px",
            visible: _vm.isExpressShow,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isExpressShow = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "140px", "label-suffix": "：" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "快递单号" } },
                [
                  _c("v-input", {
                    model: {
                      value: _vm.express.expNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.express, "expNum", $$v)
                      },
                      expression: "express.expNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "快递公司" } },
                [
                  _c(
                    "v-select2",
                    _vm._b(
                      {
                        ref: "expCode",
                        on: { onChange: _vm.expChange },
                        model: {
                          value: _vm.express.expCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.express, "expCode", $$v)
                          },
                          expression: "express.expCode",
                        },
                      },
                      "v-select2",
                      _vm.expCodeParams,
                      false
                    )
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("v-button", {
                attrs: { type: "default", text: "取 消" },
                on: {
                  click: function ($event) {
                    _vm.isExpressShow = false
                  },
                },
              }),
              _c("v-button", {
                attrs: { text: "确 定" },
                on: { click: _vm.addExpress },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: "物流详情",
            width: "600px",
            visible: _vm.isExpressInfoShow,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isExpressInfoShow = $event
            },
          },
        },
        [
          _vm._l(_vm.expressInfoList, function (item, index) {
            return _c("div", { key: index, staticClass: "expBox" }, [
              _c("span", [_vm._v(_vm._s(item.ftime))]),
              _c("span", [_vm._v(_vm._s(item.context))]),
            ])
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("v-button", {
                attrs: { type: "default", text: "关 闭" },
                on: {
                  click: function ($event) {
                    _vm.isExpressInfoShow = false
                  },
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "工单转派", visible: _vm.isTransfer, width: "600px" },
          on: {
            "update:visible": function ($event) {
              _vm.isTransfer = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-radio-group",
                {
                  staticClass: "transferRadio",
                  model: {
                    value: _vm.transferRadio,
                    callback: function ($$v) {
                      _vm.transferRadio = $$v
                    },
                    expression: "transferRadio",
                  },
                },
                _vm._l(_vm.transferList, function (item, index) {
                  return _c(
                    "el-radio",
                    { key: item.id, attrs: { label: index } },
                    [
                      _c("span", [
                        _vm._v(_vm._s(item.userName + "-" + item.phone)),
                      ]),
                      _c("span", { staticClass: "span1" }, [
                        _vm._v(_vm._s(item.processingCount) + "个工单处理中"),
                      ]),
                    ]
                  )
                }),
                1
              ),
              _c("el-pagination", {
                attrs: {
                  small: "",
                  layout: "prev, pager, next",
                  "page-size": _vm.pageSize,
                  total: _vm.total,
                  "hide-on-single-page": _vm.isHideOnSinglePage,
                },
                on: { "current-change": _vm.changeTransferList },
              }),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.isTransfer = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.transferGrant },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加回访记录",
            visible: _vm.revisitVisible,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.revisitVisible = $event
            },
          },
        },
        [
          _c(
            "col2-block",
            { staticClass: "revisit" },
            [
              _c(
                "col2-item",
                { staticClass: "item", attrs: { span: 20, label: "方式" } },
                [
                  _c("v-checkbox", {
                    attrs: {
                      type: "border",
                      label: "面访",
                      value: _vm.revisitType == 1,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.changeRevisitType(1)
                      },
                    },
                  }),
                  _c("v-checkbox", {
                    attrs: {
                      type: "border",
                      label: "电话",
                      value: _vm.revisitType == 2,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.changeRevisitType(2)
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._l(_vm.evaluationItem, function (item, index) {
                return _c(
                  "col2-item",
                  {
                    key: index,
                    staticClass: "item",
                    attrs: { span: 20, label: item.name },
                  },
                  [
                    _c("el-rate", {
                      model: {
                        value: item.value,
                        callback: function ($$v) {
                          _vm.$set(item, "value", $$v)
                        },
                        expression: "item.value",
                      },
                    }),
                  ],
                  1
                )
              }),
              _c(
                "col2-item",
                { staticClass: "item" },
                [
                  _c("v-textarea", {
                    staticClass: "revisitText",
                    attrs: { placeholder: "请填写回访结果" },
                    model: {
                      value: _vm.revisitText,
                      callback: function ($$v) {
                        _vm.revisitText = $$v
                      },
                      expression: "revisitText",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.workOrderRevisit },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }