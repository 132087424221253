// 工单分类页面 获取左侧公司列表
const getRegionTreeURL = `${API_CONFIG.butlerBaseURL}region/getRegionTree`
// 根据公司id获取 工单树 接口
const getTreeByRegionIdURL = `${API_CONFIG.taskDispatchURL}dispatch/category/getTreeByRegion`
// 根据工单类型父id 添加工单类型 如果没有父级 id取0
const addRegionTreeURL = `${API_CONFIG.taskDispatchURL}dispatch/category/add`
// 根据公司id删除工单类型
const deleteRegionTreeURL = `${API_CONFIG.taskDispatchURL}dispatch/category/delete`
// 根据公司id和工单类型id判断是否可以删除
const preDeleteURL = `${API_CONFIG.taskDispatchURL}dispatch/category/preDelete`


// 服务设置页面api
// 根据id获取工单类型配置列表
const getTreeURL = `${API_CONFIG.taskDispatchURL}dispatch/category/getTree`
// 根据小区id更改工单类型
const updateURL = `${API_CONFIG.taskDispatchURL}dispatch/category/update`
// dispatch/category/copy
// 根据小区id 同步工单类型配置
const copyURL = `${API_CONFIG.taskDispatchURL}dispatch/category/copy`

// 获取收费科目列表
const chargeSubjectURl = `${API_CONFIG.butlerBaseURL}chargeSubjectSelect2`

// 获取收费明细
const getFeeItemURL = `${API_CONFIG.origin}task-dispatch/dispatch/category/feeItem`

export {
  getRegionTreeURL,
  getTreeByRegionIdURL,
  preDeleteURL,
  addRegionTreeURL,
  deleteRegionTreeURL,
  getTreeURL,
  updateURL,
  copyURL,
  chargeSubjectURl,
  getFeeItemURL
}
